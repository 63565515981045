import React from "react";
import { Text, Image } from "@atoms";
import clsx from "clsx";
import { useAppState } from "@state";

const PersonModal = ({ uid, image, name, title, bio }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        key={uid}
        className="relative mx-auto flex w-full max-w-5xl shrink-0 flex-col items-start bg-background-color text-center sm:h-auto md:flex-row"
      >
        {image && (
          <div className="relative mx-auto aspect-square w-full md:mx-0 md:w-2/5 xl:w-1/5">
            <Image
              key={image.uid}
              image={image}
              ixParams={{ facepad: 8, crop: "faces,edges" }}
              fitHeight
            />
          </div>
        )}
        <div className="p-8 text-left">
          <Text variant="h5" className="text-text-color">
            {name}
          </Text>
          <Text
            variant="body"
            className="mt-2 font-bold text-pink group-hover:text-orange"
          >
            {title}
          </Text>
          <div className="max-h-[10rem] overflow-auto sm:h-full md:max-h-full">
            <Text variant="sm" className="mt-4" richText>
              {bio}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonCard = ({ image, name, bio, title }) => {
  const [, dispatch] = useAppState();

  const triggerModal = () => {
    dispatch({
      type: "openModal",
      title: name,
      description: `${name}'s short biography`,
      content: React.memo(() => (
        <PersonModal image={image} name={name} title={title} bio={bio} />
      )),
      hideButton: true,
    });
  };

  return (
    <button
      type="button"
      className={clsx(
        "group w-full space-y-4 text-center sm:basis-1/3 md:basis-1/5 lg:basis-1/6"
      )}
      onClick={triggerModal}
    >
      <div className="relative aspect-square">
        <Image
          key={image.uid}
          image={image}
          ixParams={{
            facepad: 8,
            crop: "faces,edges",
          }}
          fitHeight
          className="duration-700 ease-in-out-quad group-hover:scale-105"
        />
      </div>
      <Text variant="h6" className="text-text-color">
        {name}
      </Text>
      <Text
        variant="body"
        className="font-bold text-pink group-hover:text-orange"
      >
        {title}
      </Text>
    </button>
  );
};

export default PersonCard;
