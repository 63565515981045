import { imageUtils } from "@utils";

const { buildUrl, defaultParams } = imageUtils;

const resolveImage = _image => {
  const image = Array.isArray(_image) ? _image?.[0] : _image;
  if (image) {
    const { url, height, width, hasFocalPoint, focalPoint } = image;
    return {
      ...image,
      eagerUrl: buildUrl(url, defaultParams(true, height / width, 1)),
      focalPoint: hasFocalPoint ? focalPoint : false,
    };
  }
  return null;
};

export default resolveImage;
